import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, useMediaQuery } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Image from "material-ui-image";
import Link from "../link";
import assetTheme from "../../theme";
import CardSpot from "./card-spot";
import { getAssetSlug, useQuery } from "../../utils";
import { isDateValid } from "../../utils/dateUtils";
import DescriptionDialog from "./description-dialog";

const useStyles = makeStyles((theme) => ({
  titleMain: {
    textAlign: "center",
    width: "100%",
    color: assetTheme.palette.text.primary,
    textTransform: "uppercase",
    "@media (min-height: 1080px)": {
      fontSize: 28,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
  },
  spots: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    marginTop: theme.spacing(4),
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3),
  },
  descriptionContainer: {
    height: 224,
    position: "relative",
    bottom: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column-reverse",
      textAlign: "center",
      height: 540,
      marginBottom: theme.spacing(2),
    },
    "@media (min-height: 720px) and (min-width: 1280px)": {
      position: "absolute",
      height: 234,
    },
    "@media (min-height: 1080px)": {
      height: 427,
    },
    "@media (min-height: 1440px)": {
      height: 584,
    },
  },
  assetDescription: {
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "100%",
      textShadow: "2px 2px 4px #ffffff",
    },
  },
  titleText: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    "@media (min-height: 1080px)": {
      fontSize: 28,
    },
  },
  descriptionText: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    maxWidth: "100%",
    display: "block",
    "@media (min-height: 1080px)": {
      fontSize: 15,
    },
  },
  ellipsis: {
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      WebkitLineClamp: 6,
    },
  },
  moreDetail: {
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textTransform: "initial",
    height: 20,
    paddingLeft: 0,
    color: theme.palette.primary.contrastText,
  },
  dossier: {
    margin: theme.spacing(4),
    marginTop: 0,
    fontWeight: "bold",
    color: theme.palette.primary.contrastText,
    "@media (min-height: 1080px)": {
      fontSize: 15,
    },
  },
}));

// this is to refactor. duplicated "home/header"
export const getUrlFromSpotSlug = (spot, asset, start, end, longTerm) => {
  const customTitle = spot.data?.title || "";
  const spotType = spot.data?.type;

  switch (spotType) {
    case "adspace":
      if (isDateValid(start) && isDateValid(end)) {
        return `/active-adspaces/${asset.id}/?start=${start}&end=${end}&spot=${spot.slug}&spotTitle=${customTitle}`;
      }
      return `/active-adspaces/${asset.id}?spot=${spot.slug}&spotTitle=${customTitle}`;

    default:
      if (longTerm && isDateValid(start)) {
        return `/active-spaces/${getAssetSlug(asset)}/?start=${start}&long_term=true&spot=${
          spot.slug
        }&spotTitle=${customTitle}`;
      }
      if (isDateValid(start) && isDateValid(end)) {
        return `/active-spaces/${getAssetSlug(asset)}/?start=${start}&end=${end}&spot=${
          spot.slug
        }&spotTitle=${customTitle}`;
      }
      return `/active-spaces/${getAssetSlug(asset)}/?spot=${spot.slug}&spotTitle=${customTitle}`;
  }
};

function AssetSpots({ spots, region, asset }) {
  const classes = useStyles({ ...assetTheme });
  const [openDescriptionDialog, setDescriptionDialog] = useState(false);
  const [showMoreDetail, setShowMoreDetail] = useState(false);
  const refDescription = useRef();

  const mobile = useMediaQuery(assetTheme.breakpoints.down("sm"));
  const query = useQuery();
  const start = query.get("start");
  const end = query.get("end");
  const longTerm = query.get("long_term");

  useEffect(() => {
    const { clientHeight } = refDescription.current;
    if (!mobile) {
      setShowMoreDetail(clientHeight > 60);
    } else {
      setShowMoreDetail(clientHeight > 120);
    }
    refDescription.current.style.display = "-webkit-box";
  }, [asset]);

  useEffect(() => {
    if (showMoreDetail) {
      refDescription.current.classList.add(classes.ellipsis);
    }
  }, [showMoreDetail]);

  return (
    <Box mt={2} pt={2}>
      <Box ml={[2, 4]}>
        <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
          <Link color="inherit" to="/">
            <Typography variant="h6" style={{ color: assetTheme.palette.gray71 }}>
              Home
            </Typography>
          </Link>
          {region.name && (
            <Link color="inherit" to={`/region-assets/${region.id}/`}>
              <Typography variant="h6" style={{ color: assetTheme.palette.gray71 }}>
                {region.name}
              </Typography>
            </Link>
          )}
          <Typography variant="h6" style={{ color: assetTheme.palette.black }}>
            {asset?.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Typography variant="h2" className={classes.titleMain}>
        <Trans>Selecciona la opción que más se ajuste a tus necesidades</Trans>
      </Typography>
      <Box className={classes.spots}>
        {spots.map((spot) => (
          <CardSpot
            key={spot.slug}
            spot={spot}
            assetId={asset?.id}
            to={getUrlFromSpotSlug(spot, asset, start, end, longTerm)}
          />
        ))}
      </Box>
      <Box display="flex" className={classes.descriptionContainer}>
        <Image
          src={asset?.thumb_url}
          style={{
            height: "100%",
            padding: "0",
            width: "100%",
            filter: "brightness(70%)",
            flex: 1,
          }}
          imageStyle={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
          }}
        />
        <Box flex={1} className={classes.assetDescription}>
          <Typography variant="h3" className={classes.titleText}>
            {asset?.name}
          </Typography>
          <Typography
            variant="body1"
            className={classes.descriptionText}
            ref={refDescription}
            style={!showMoreDetail ? { marginBottom: assetTheme.spacing(2) } : {}}
          >
            {asset?.description}
          </Typography>
          {showMoreDetail && (
            <>
              <Button
                size="small"
                variant="text"
                color="primary"
                onClick={() => setDescriptionDialog(true)}
                className={classes.moreDetail}
              >
                <Typography variant="body1">
                  <Trans>Continuar leyendo</Trans>
                </Typography>
              </Button>
              <DescriptionDialog
                fullScreen={mobile}
                name={asset?.name}
                description={asset?.description}
                open={openDescriptionDialog}
                onClose={() => setDescriptionDialog(false)}
              />
            </>
          )}
          {asset?.dossier_advertising && (
            <Link target="_blank" href={asset.dossier_advertising}>
              <Typography variant="body1" className={classes.dossier}>
                <Trans>Ver dossier</Trans>
              </Typography>
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default AssetSpots;
